import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { Users } from '../../../@models/users.model';
import { DashboardService } from '../../../@services/dashboard.service';
import { UsersService } from '../../../@services/users.service';

interface UserGenders {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'ngx-partner-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  userGenders: UserGenders[] = [
    { value: 'Male', viewValue: 'Male' },
    { value: 'Female', viewValue: 'Female' },
    { value: 'Others', viewValue: 'Others' },
  ];

  @ViewChild('updateProfileForm', { static: false }) public updateProfileForm: NgForm;
  @ViewChild('updatePasswordForm', { static: false }) public updatePasswordForm: NgForm;
  profileFormSubmitted = false;
  passwordFormSubmitted = false;

  userDetails = new Users();

  old_password_text: any;
  new_password_text: any;
  confirm_password_text: any;
  userName: string;
  userEmail: string;
  userRole: any;
  userId: string;
  userBirthDate: any;

  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;

  constructor(private datePipe: DatePipe,
    private toastrService: NbToastrService,
    private usersService: UsersService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.userId = localStorage.getItem('user_id');
    this.userRole =  JSON.parse(localStorage.getItem('user_role') || '{}');
    this.getProfile();
  }

  preventTyping(event: KeyboardEvent) {
    event.preventDefault();
  }

  openDatePicker(elementId: string) {
    const datePickerInput = document.getElementById(elementId);
    if (datePickerInput) {
      datePickerInput.focus();
      const event = new MouseEvent('mousedown');
      datePickerInput.dispatchEvent(event);
    }
  }  
  
  getProfile() {
    let payload = {
      id: this.userId,
    };
    this.usersService.getUserProfile(payload).subscribe(response => {
      if (response) {
        this.userDetails = response.result;
        const lastName = response.result.user_last_name == null ? '' : response.result.user_last_name;
        this.userBirthDate = new Date(this.userDetails.user_birth_date);
        localStorage.setItem('user_name', response.result.user_first_name + ' ' + lastName);
      }
    }, error => {
      if (error) {
        this.dashboardService.backToHome();
      }
    });
  }


  onSubmitProfileForm = (payload) => {
    this.profileFormSubmitted = true;
    let profileData = {
      'id': this.userId,
      'user_first_name': payload.user_first_name,
      'user_last_name': payload.user_last_name ? payload.user_last_name : '',
      'partner_company_abn_acn': payload.partner_company_abn_acn,
      'partner_contact_person': payload.partner_contact_person,
      'partner_office_address': payload.partner_office_address,
      'user_birth_date': this.datePipe.transform(this.userBirthDate, "yyyy/MM/dd"),
      'user_gender': payload.user_gender,
      'user_mobile': payload.user_mobile,
      'profile_image': '',
      'updated_id': this.userId
    };
    this.usersService.updateUserProfile(profileData).subscribe(response => {
      if (response) {
        if (response.status == 1) {
          this.profileFormSubmitted = false;
          this.updateProfileForm.resetForm();
          this.toastrService.success('Success', response.message);
          this.getProfile();
        }
        else {
          this.profileFormSubmitted = false;
          this.toastrService.warning('Warning', response.message);
        }
      }
    }, error => {
      if (error) {
        this.profileFormSubmitted = false;
        const message = `Oops! Something went wrong.`;
        this.toastrService.danger('Failure', message);
      }
    });
  }

  onSubmitPasswordForm = (payload) => {
    this.passwordFormSubmitted = true;
    let passwordData = {
      'id': this.userId,
      'password': payload.old_password,
      'new_password': payload.new_password,
    };
    this.usersService.passwordUpdate(passwordData).subscribe(response => {
      if (response) {
        if (response.status == 1) {
          this.passwordFormSubmitted = false;
          this.updatePasswordForm.resetForm();
          this.toastrService.success('Success', response.message);
        }
        else {
          this.passwordFormSubmitted = false;
          this.toastrService.warning('Warning', response.message);
        }
      }
    }, error => {
      if (error) {
        this.passwordFormSubmitted = false;
        const message = `Oops! Something went wrong.`;
        this.toastrService.danger('Failure', message);
      }
    });
  }

  togglePasswordVisibility(field: string) {
    if (field === 'current') {
      this.showOldPassword = !this.showOldPassword;
    } else if (field === 'new') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirm') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }
}
