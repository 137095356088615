import { environment } from "../../environments/environment";

// Localhost URL
const BASE_URL = environment.apiUrl;
const API = 'api';

export const AppConfig = {

    apiToken: environment.apiToken,
    site_icon: 'https://manage.universalsounds.world/favicon.ico',

    //User routes
    cancelUserPlanSubscription: BASE_URL + API + '/cancel-user-plan-subscription',
    adminSignin: BASE_URL + API + '/admin-signin',
    getProfile: BASE_URL + API + '/get-profile',
    updateProfile: BASE_URL + API + '/edit-profile',
    updatePassword: BASE_URL + API + '/update-password',
    forgotPasswordAdminMail: BASE_URL + API + '/forgot-password-admin-mail',
    reactivatePaymentSubscription: BASE_URL + API + '/reactivate-subscription',
    retrieveInvoice: BASE_URL + API + '/retrieve-invoice',
    adminPartnerUpdatePassword: BASE_URL + API + '/admin-partner-update-password',

    //Dashboard Routes
    viewMostPlayedContents: BASE_URL + API + '/view-most-played-contents',
    viewMostDownloadedContents: BASE_URL + API + '/view-most-downloaded-contents',
    viewMostLikedContents: BASE_URL + API + '/view-most-liked-contents',
    viewSubscriptionsCounts: BASE_URL + API + '/view-subscriptions-counts',
    viewUsersCounts: BASE_URL + API + '/view-users-counts',
    viewRevenues: BASE_URL + API + '/view-revenues',

    //Channels routes
    viewAllChannels: BASE_URL + API + '/view-all-channels',
    detailChannel: BASE_URL + API + '/detail-channel',
    addChannel: BASE_URL + API + '/add-channel',
    editChannelBasicDetails: BASE_URL + API + '/edit-channel-basic-details',
    editChannelMultimedia: BASE_URL + API + '/edit-channel-multimedia',
    editChannelCommercial: BASE_URL + API + '/edit-channel-commercial',
    addChannelCommercials: BASE_URL + API + '/add-channel-commercials',
    editChannelPublishStatus: BASE_URL + API + '/edit-channel-publish-status',

    //Partner Routes
    addPartner: BASE_URL + API + '/add-partner',
    viewPartners: BASE_URL + API + '/view-all-partner',
    editPartnerProfile: BASE_URL + API + '/edit-partner',
    viewAllAlbumsAdminRequest: BASE_URL + API + '/view-all-albums-admin-request',
    adminPartnerRequestPublish: BASE_URL + API + '/admin-partner-request-publish',

    //Albums routes
    viewAllAlbums: BASE_URL + API + '/view-all-albums',
    detailAlbum: BASE_URL + API + '/detail-album',
    addAlbum: BASE_URL + API + '/add-album',
    editAlbumBasicDetails: BASE_URL + API + '/edit-album-basic-details',
    editAlbumContents: BASE_URL + API + '/edit-album-contents',
    editAlbumMetadata: BASE_URL + API + '/edit-album-metadata',

    //Contents routes
    viewAllContents: BASE_URL + API + '/view-all-contents',
    viewNonAlbumContents: BASE_URL + API + '/view-non-album-contents',
    detailContent: BASE_URL + API + '/detail-content',
    addContent: BASE_URL + API + '/add-content',
    editContentBasicDetails: BASE_URL + API + '/edit-content-basic-details',
    editContentMetadata: BASE_URL + API + '/edit-content-metadata',
    editContentMultimedia: BASE_URL + API + '/edit-content-multimedia',
    contentStatus: BASE_URL + API + '/content-status',
    uploadContent: BASE_URL + API + '/uploads3',


    //Artists routes
    viewArtists: BASE_URL + API + '/view-artists',
    detailArtist: BASE_URL + API + '/detail-artist',
    addArtist: BASE_URL + API + '/add-artist',
    editArtistBasicDetails: BASE_URL + API + '/edit-artist-basic-details',
    editArtistGenres: BASE_URL + API + '/edit-artist-genres',
    editArtistMetadata: BASE_URL + API + '/edit-artist-metadata',

    //Genres routes
    viewGenres: BASE_URL + API + '/view-genres',
    detailGenre: BASE_URL + API + '/detail-genre',
    addGenre: BASE_URL + API + '/add-genre',
    editGenre: BASE_URL + API + '/edit-genre',

    //Subscriber routes
    viewSubscribers: BASE_URL + API + '/view-subscribers',
    detailSubscriber: BASE_URL + API + '/detail-subscriber',
    editSubscriber: BASE_URL + API + '/edit-subscriber',
    editSubscriberPassword: BASE_URL + API + '/edit-subscriber-password',
    viewSubscriberActivePlansSubscriptions: BASE_URL + API + '/view-user-active-plans-subscriptions',

    //Subscription Payments routes
    viewSubscribersSubscriptionPayments: BASE_URL + API + '/subscription-album-payments-users',
    viewNewlySubscribedSubscriptions: BASE_URL + API + '/view-newly-subscribed-subscriptions',
    subscriptionsRevenue: BASE_URL + API + '/subscriptions-revenue',
    albumsRevenue: BASE_URL + API + '/albums-revenue',
    DownloadIndividaulAlbumSaleRevenue: BASE_URL + API + '/individual-album-sale-revenue-no-calculation',

    //Notification Routes
    addNotification: BASE_URL + API + '/send-push-notification',
    getNotifications: BASE_URL + API + '/get-push-notification',

    //Revenue history Mail Routes
    sendRevenueEmail: BASE_URL + API + '/partner-revenue-email-send',

    //API Tokens Routes
    viewTokens: BASE_URL + API + '/view-tokens',
    addToken: BASE_URL + API + '/add-token',

    //Success Codes Routes
    viewSuccessCodes: BASE_URL + API + '/view-success-codes',
    addSuccessCode: BASE_URL + API + '/add-success-code',
    editSuccessCode: BASE_URL + API + '/edit-success-code',

    //Error Codes Routes
    viewErrorCodes: BASE_URL + API + '/view-error-codes',
    addErrorCode: BASE_URL + API + '/add-error-code',
    editErrorCode: BASE_URL + API + '/edit-error-code',

    //Notification Texts Routes
    viewNotificationTexts: BASE_URL + API + '/view-notification-texts',
    addNotificationText: BASE_URL + API + '/add-notification-text',
    editNotificationText: BASE_URL + API + '/edit-notification-text',

    //Notification Emails Routes
    viewAllNotificationEmails: BASE_URL + API + '/view-all-notification-emails',
    addNotificationEmail: BASE_URL + API + '/add-notification-email',
    editNotificationEmail: BASE_URL + API + '/edit-notification-email',

    //User Roles routes
    viewUserRoles: BASE_URL + API + '/view-user-roles',
    addUserRole: BASE_URL + API + '/add-user-role',
    editUserRole: BASE_URL + API + '/edit-user-role',

    //partner portal Routes
    getPartnerProfile: BASE_URL + API + '/get-profile-partner',
    viewMostPlayedContentsForPartners: BASE_URL + API + '/view-most-played-contents-partner',
    viewMostLikedContentsForPartners: BASE_URL + API + '/view-most-liked-contents-partner',
    viewMostDownloadedContentsForPartners: BASE_URL + API + '/view-most-downloaded-contents-partner',
    addPartnerAlbumRequest: BASE_URL + API + '/add-partner-album-request',
    viewAllAlbumsPartner: BASE_URL + API + '/view-all-albums-partner',

    // common Routes for admin & partner
    airTimeRevenue: BASE_URL + API + '/air-time-revenue',
    individualAlbumSaleRevenue: BASE_URL + API + '/individual-album-sale-revenue',
    adminPartnerRequestDeny: BASE_URL + API + '/admin-partner-request-deny',
    downloadRevenueReport: BASE_URL + API + '/revenue-report',
    totalAirtime: BASE_URL + API + '/air-time-no-calculation',
    airTiemRevenueReport: BASE_URL + API + '/air-time-revenue-report',
    individualAlbumRevenueReport: BASE_URL + API + '/individual-album-revenue-report'
};
