import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/internal/operators/map";
import { Users } from '../@models/users.model';
import { AppConfig } from '../@config/app-config';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'MyClientCert': '',
      'apitoken': AppConfig.apiToken,
    })
  };

  constructor(private router: Router, private http: HttpClient) { }

  reactivateSubscription = (payload: any) => {
    return this.http.post<any>(AppConfig.reactivatePaymentSubscription, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };
  
  getInvoice = (payload: any) => {
    return this.http.post<any>(AppConfig.retrieveInvoice, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  cancelUserPlanSubscription = (payload: any) => {
    return this.http.post<Users>(AppConfig.cancelUserPlanSubscription, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  newAdminSignin = (payload) => {
    return this.http.post<Users>(AppConfig.adminSignin, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getUserProfile = (payload) => {
    return this.http.post<Users>(AppConfig.getProfile, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  updateUserProfile = (payload) => {
    return this.http.post<Users>(AppConfig.updateProfile, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  passwordUpdate = (payload) => {
    return this.http.post<Users>(AppConfig.updatePassword, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  adminPartnerUpdatePassword = (payload) => {
    return this.http.post<Users>(AppConfig.adminPartnerUpdatePassword, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  forgotPasswordAdminEmail = (payload) => {
    return this.http.post<Users>(AppConfig.forgotPasswordAdminMail, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getSubscribers = (payload) => {
    return this.http.post<Users>(AppConfig.viewSubscribers, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getDetailSubscriber = (payload) => {
    return this.http.post<Users>(AppConfig.detailSubscriber, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  updateSubscriber = (payload) => {
    return this.http.post<Users>(AppConfig.editSubscriber, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  updateSubscriberPassword = (payload) => {
    return this.http.post<Users>(AppConfig.editSubscriberPassword, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getSubscriberActivePlansSubscriptions = (payload: any) => {
    return this.http.post<Users>(AppConfig.viewSubscriberActivePlansSubscriptions, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  backToSubscribersRoute() {
    this.router.navigate(['/pages/subscribers/view']);
  }
}
