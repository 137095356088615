import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/internal/operators/map";
import { Injectable } from '@angular/core';
import { AppConfig } from '../@config/app-config';
import { Contents } from '../@models/contents.model';
import { Router } from '@angular/router';
import { Subscriptions } from '../@models/subscriptions.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'MyClientCert': '',
      'apitoken': AppConfig.apiToken,
    })
  };

  constructor(private router: Router, private http: HttpClient) { }

  backToHome() {
    this.router.navigate(['/pages/dashboard']);
  }

  backToPartnerHome() {
    this.router.navigate(['/partner/dashboard']);
  }

  getRevenues = (payload) => {
    return this.http.post<Subscriptions>(AppConfig.viewRevenues, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getMostLikedContents = (payload) => {
    return this.http.post<Contents>(AppConfig.viewMostLikedContents, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getMostPlayedContents = (payload) => {
    return this.http.post<Contents>(AppConfig.viewMostPlayedContents, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getMostDownloadedContents = (payload) => {
    return this.http.post<Contents>(AppConfig.viewMostDownloadedContents, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getSubscriptionsCounts = (payload) => {
    return this.http.post<Contents>(AppConfig.viewSubscriptionsCounts, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getUsersCounts = (payload) => {
    return this.http.post<Contents>(AppConfig.viewUsersCounts, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getMostLikedContentsForPartner = (payload) => {
    return this.http.post<Contents>(AppConfig.viewMostLikedContentsForPartners, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getMostPlayedContentsForPartner = (payload) => {
    return this.http.post<Contents>(AppConfig.viewMostPlayedContentsForPartners, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };

  getMostDownloadedContentsForPartner = (payload) => {
    return this.http.post<Contents>(AppConfig.viewMostDownloadedContentsForPartners, payload, this.httpOptions).pipe(map(result => {
      return result;
    }));
  };
  
}
