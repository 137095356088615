import { Injectable } from '@angular/core';
import { Role } from '../@models/role';
import { User } from '../@models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: User;

  isAuthorized() {
    return !!this.user;
  }

  get isLoggedIn(): boolean {
    const userId = localStorage.getItem('user_id');
    return (userId !== null) ? true : false;
  }

  public get getUserRole() {
    return this.user;
  }

  hasRole(role: Role) {
    return this.isAuthorized() && this.user.role === role;
  }

  login(role: Role) {
    this.user = { role: role };
    localStorage.setItem('user_role', JSON.stringify(this.user));
  }

  logout() {
    localStorage.clear();
    this.user = null;
  }
}
