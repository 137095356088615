import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created with <span class="text-blue">♥</span> by <b><a class="cursor-pointer text-blue" href="https://www.nextsavy.com/" target="_blank">Nextsavy Technologies</a></b> 2023
    </span>
  `,
})
export class FooterComponent {
}
