import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule, Component } from '@angular/core';
import {
  NbAuthComponent,
} from '@nebular/auth';
import { LoginComponent } from './@auth/components/login/login.component';
import { Role } from './@models/role';
import { RoleGuard } from './@core/role.guard';

@Component({ template: '' })
export class DummyComponent {}

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    data: {
      roles: [
        Role.Admin,
      ]
    },
  },
  {
    path: 'partner',
    loadChildren: () => import('./partner/partner.module')
      .then(m => m.PartnerModule),
    data: {
      roles: [
        Role.Partner,
      ]
    },
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
    ],
  },
  {
    path: '',pathMatch: 'full',
    canActivate: [RoleGuard],
    component: DummyComponent
  },
  {
    path: '**',
    canActivate: [RoleGuard],
    component: DummyComponent
  }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
