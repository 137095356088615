
<div class="logo2 mb-5"><img src="assets/images/logo-text-black.png" alt="Universal Sounds Music Streaming" title="Universal Sounds Music" /></div>

<form #addSigninForm="ngForm" (ngSubmit)="addSigninForm.form.valid && onSubmitSignin(addSigninForm.value)">
    <div class="form-group">
        <label>User Email</label>
        <span class="invalid-feedback form-text" *ngIf="addSigninForm.submitted && addSigninForm.invalid && addSigninForm.controls.user_email.errors?.required">Please Enter User Email</span>
        <span class="invalid-feedback form-text" *ngIf="addSigninForm.submitted && addSigninForm.invalid && addSigninForm.controls.user_email.errors?.email">Please Enter Valid User Email</span>
        <input type="text" name="user_email" #user_email="ngModel" [(ngModel)]="user_email_text" class="form-control form-control-md" required email [ngClass]="{ 'is-invalid': addSigninForm.submitted && addSigninForm.controls.user_email.invalid }">
    </div>
    <div class="form-group">
        <div class="form-group">
            <label>User Password</label>
            <span class="invalid-feedback form-text" *ngIf="addSigninForm.submitted && addSigninForm.invalid && addSigninForm.controls.password.errors?.required">Please Enter Password</span>
            <input type="password" name="password" #password="ngModel" [(ngModel)]="password_text" class="form-control form-control-md" required [ngClass]="{ 'is-invalid': addSigninForm.submitted && addSigninForm.controls.password.invalid }">
        </div>
    </div>
    <a class="float-right mb-2 cursor-pointer forgot-pwd" (click)="openforgotPasswordPopup(forgotPasswordPopup)">Forgot Password</a>
    <button *ngIf="!signinFormSubmitting" type="submit" class="btn btn-primary btn-lg btn-block">Sign in</button>
    <button *ngIf="signinFormSubmitting" class="btn btn-primary btn-lg btn-block">
        <div class="spinner-border text-white" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </button>
</form>

<!-- Modal -->

<ng-template #forgotPasswordPopup>
    <div class="modal-content border-0 success-wrap">
        <div class="modal-header align-items-center border-bottom-0 pt-0 px-2 pb-3">
            <h1 class="modal-title my-0 fw-bold modal-font" id="exampleModalLabel">Reset Your Password</h1>
            <div class="text-right ml-3">
                <button type="button" class="close close-3" (click)="cancel()">
                    <nb-icon icon="close-outline"></nb-icon>
                </button>
              </div>
        </div>
        <div class="modal-body p-2">
            <form #forgotPasswordForm="ngForm" autocomplete="off" (ngSubmit)="forgotPasswordForm.form.valid && onSubmitForgotPassword(forgotPasswordForm.value)">
                <p class="modal-font">You will receive an email with your updated password.</p>
                <div class="form-group">
                    <span class="invalid-feedback form-text modal-font" *ngIf="forgotPasswordForm.submitted && forgotPasswordForm.invalid && forgotPasswordForm.controls.forgot_email.errors?.required">Please Enter User Email</span>
                    <span class="invalid-feedback form-text modal-font" *ngIf="forgotPasswordForm.submitted && forgotPasswordForm.invalid && forgotPasswordForm.controls.forgot_email.errors?.email">Please Enter Valid User Email</span>
                    <input type="text" name="forgot_email" #forgot_email="ngModel" [(ngModel)]="forgot_email_text" placeholder="Email" class="form-control form-control-md modal-font" required email>
                </div>
                <div class="form-group text-right pt-4 mb-0">
                    <a class="mr-2 btn-gray" (click)="cancel()" nbButton>Cancel</a>
                    <button class="bg-blue text-white publish-btn" data-ktwizard-type="action-submit" type="submit" nbButton>
                        Submit
                    </button>
                    <div *ngIf="forgotPasswordFormSubmitting" class="col-md-12 text-center">
                        <div class="spinner-border text-blue mt-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
              
            </form>
        </div>
    </div>
</ng-template>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header border-bottom-0 pt-0 px-2 pb-3">
                <h2 class="modal-title modal-font" id="exampleModalLabel">Reset your password</h2>
                <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><nb-icon icon="close-outline"></nb-icon></span>
                </a>
            </div>
            <div class="modal-body p-2">
                <form>
                    <p class="modal-font">Don't worry, we'll send you an email to reset your password.</p>
                    <div class="form-group">
                        <input class="form-control form-control-md modal-font" type="email" name="email" required="" placeholder="Your Email" autocomplete="off">
                    </div>
                    <div class="form-group pt-1">
                        <a class="btn btn-block btn-primary btn-xl" href="index.html">Reset Password</a>
                    </div>
                </form>
            </div>
            <div class="modal-footer text-center modal-font">
                Don't have an massage?
                <a href="#" class=""> Resend</a>
            </div>
        </div>
    </div>
</div>
<!-- end modal -->

<!-- password protection modal start-->
<ng-template #passwordPopup>
<div class="modal-content border-0 success-wrap" style="width: 20rem !important;" *ngIf="isPopupVisibleForPlans">
    <div class="modal-header align-items-center border-bottom-0 pt-0 px-2 pb-3">
        <h1 class="modal-title my-0 fw-bold modal-font" id="exampleModalLabel">Access Verification!</h1>
    </div>
    <div class="modal-body p-2">
        <form (ngSubmit)="redirectValidation()">
            <div class="form-group">
                <span class="modal-font">Enter Password to Access Site</span>
                <input type="password" name="user_password" id="user_password" [(ngModel)]="user_password" placeholder="Enter Password" autocomplete="off" class="form-control form-control-md modal-font">
                <span class="invalid-feedback form-text modal-font">{{ errorMessage }}</span>
            </div>
            <div class="form-group text-right pt-4 mb-0">
                <button class="bg-blue text-white publish-btn" data-ktwizard-type="action-submit" type="submit" nbButton>
                    Submit
                </button>
            </div>
          
        </form>
    </div>
</div>
</ng-template>

<!-- password protection modal end-->