<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle cursor-pointer">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo cursor-pointer" href="" (click)="navigateHome()"><img src="assets/images/logo-text-black.png" /></a>
    </div>
    <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
    <nb-actions size="small">
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <nb-user *ngIf="isLoggedIn" [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="userName" [picture]="userImage" [title]="userRole.role">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>