import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const userRole = localStorage.getItem('user_role_id');

    if (userRole === '1') {
      this.router.navigateByUrl('/pages');
      return false;
    } else if (userRole === '3') {
      this.router.navigateByUrl('/partner');
      return false;
    } else {
      this.router.navigateByUrl('/auth/login');
      return true;
    }
  }
}
