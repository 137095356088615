import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbToastrService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../@services/auth.service';
import { Router } from '@angular/router';
import { Role } from '../../../@models/role';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  userName: string;
  userEmail: string;
  userId: string;
  userImage: string;
  userRoleId: any;
  userRole: any;

  constructor(private router: Router, private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.userRole =  JSON.parse(localStorage.getItem('user_role') || '{}');
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.menuService.onItemClick().subscribe((event) => {
      this.onItemSelection(event.item.title);
    })

    this.userId = localStorage.getItem('user_id');
    this.setUserRole();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setUserRole() {
    this.userRoleId = localStorage.getItem('user_role_id');
    if (this.userRoleId == 1) {
      this.authLogin(Role.Admin);
    }
    else if (this.userRoleId == 3) {
      this.authLogin(Role.Partner);
    }
  }

  onItemSelection(title) {
    if (title === 'Log out') {
      // Do something on Log out
      this.authService.logout();
      this.toastrService.success('Success', 'Logged out Successfully!');
      this.router.navigate(['auth/login']);
    } else if (title === 'Profile') {
      if (this.userRoleId == 1) {
      // Do something on Profile
      this.router.navigate(['pages/profile']);
      }
      if (this.userRoleId == 3) { 
        this.router.navigate(['partner/profile']);
      }
    }
  }

  get isLoggedIn() {
    if (localStorage.getItem('user_id')) {
      this.userId = localStorage.getItem('user_id');
      this.userName = localStorage.getItem('user_name');
      this.userEmail = localStorage.getItem('user_email');
      this.userImage = localStorage.getItem('user_image_url');
      return true;
    }
    else {
      return false;
    }
  }

  authLogin(role: Role) {
    this.authService.login(role);
  }

  get isAuthorized() {
    return this.authService.isAuthorized();
  }

  get isAdmin() {
    return this.authService.hasRole(Role.Admin);
  }

  get isPartner() {
    return this.authService.hasRole(Role.Partner);
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    if (this.userRoleId == 1) {
      this.router.navigate(['pages/dashboard']);
    } else if (this.userRoleId == 3) {
      this.router.navigate(['partner/dashboard']);
    } 
  }
}
