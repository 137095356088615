<div class="col-12 source-sans-pro">
    <nb-card>
        <nb-card-header>Profile</nb-card-header>
        <nb-card-body>
            <form #updateProfileForm="ngForm"
                (ngSubmit)="updateProfileForm.form.valid && onSubmitProfileForm(updateProfileForm.value)">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="user_first_name" class="label"> {{ userRole.role == 'Admin' ? 'First Name' : 'Partner/Company Name' }} <span
                                    class="text-danger">*</span></label>
                            <input type="text" fullWidth id="user_first_name" [placeholder]="userRole.role == 'Admin' ? 'First Name' : 'Partner/Company Name'"
                                class="form-control form-control-md" name="user_first_name"
                                [(ngModel)]="userDetails.user_first_name" #user_first_name="ngModel" autofocus required
                                pattern="[a-zA-Z\s]*">
                            <ng-container
                                *ngIf="updateProfileForm.submitted || (user_first_name.invalid && user_first_name.touched)">
                                <p class="caption status-danger" *ngIf="user_first_name.errors?.required">
                                    {{ userRole.role == 'Admin' ? 'First Name' : 'Partner/Company Name' }} is required!
                                </p>
                                <p class="caption status-danger" *ngIf="user_first_name.errors?.pattern">
                                    {{ userRole.role == 'Admin' ? 'First Name' : 'Partner/Company Name' }} is allowed for text only
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="userRole.role == 'Admin'">
                        <div class="form-group">
                            <label for="user_last_name" class="label">Last Name <span
                                    class="text-danger">*</span></label>
                            <input type="text" fullWidth id="user_last_name" placeholder="Last Name"
                                class="form-control form-control-md" name="user_last_name"
                                [(ngModel)]="userDetails.user_last_name" #user_last_name="ngModel" autofocus required
                                pattern="[a-zA-Z\s]*">
                            <ng-container
                                *ngIf="updateProfileForm.submitted || (user_last_name.invalid && user_last_name.touched)">
                                <p class="caption status-danger" *ngIf="user_last_name.errors?.required">
                                    Last Name is required!
                                </p>
                                <p class="caption status-danger" *ngIf="user_last_name.errors?.pattern">
                                    Last Name is allowed for text only
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="userRole.role == 'Partner'">
                        <div class="form-group">
                            <label for="partner_company_abn_acn" class="label">Company ABN/ACN </label>
                            <input type="text" fullWidth id="partner_company_abn_acn" placeholder="Company ABN/ACN"
                                class="form-control form-control-md" name="partner_company_abn_acn"
                                [(ngModel)]="userDetails.partner_company_abn_acn" #partner_company_abn_acn="ngModel" autofocus pattern="[0-9]*">
                            <ng-container
                                *ngIf="updateProfileForm.submitted || (partner_company_abn_acn.invalid && partner_company_abn_acn.touched)">
                                <p class="caption status-danger" *ngIf="partner_company_abn_acn.errors?.pattern">
                                    Company ABN/ACN is allowed numbers only
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="userRole.role == 'Partner'">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="partner_contact_person" class="label">Contact Person <span
                                    class="text-danger">*</span></label>
                            <input type="text" fullWidth id="partner_contact_person" placeholder="Contact Person"
                                class="form-control form-control-md" name="partner_contact_person"
                                [(ngModel)]="userDetails.partner_contact_person" #partner_contact_person="ngModel" autofocus required>
                            <ng-container
                                *ngIf="updateProfileForm.submitted || (partner_contact_person.invalid && partner_contact_person.touched)">
                                <p class="caption status-danger" *ngIf="partner_contact_person.errors?.required">
                                    Contact Person is required!
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="partner_office_address" class="label">Office Address <span
                                    class="text-danger">*</span></label>
                            <input type="text" fullWidth id="partner_office_address" placeholder="Office Address"
                                class="form-control form-control-md" name="partner_office_address"
                                [(ngModel)]="userDetails.partner_office_address" #partner_office_address="ngModel" autofocus required>
                            <ng-container
                                *ngIf="updateProfileForm.submitted || (partner_office_address.invalid && partner_office_address.touched)">
                                <p class="caption status-danger" *ngIf="partner_office_address.errors?.required">
                                    Office Address is required!
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="userRole.role == 'Admin'">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="user_birth_date" class="label">Birth Date <span
                                    class="text-danger">*</span></label>
                            <input [nbDatepicker]="formpicker" fullWidth id="user_birth_date"
                                placeholder="Enter Date of Birth" class="form-control form-control-md"
                                name="user_birth_date" [(ngModel)]="userBirthDate" #user_birth_date="ngModel" autofocus
                                required (keydown)="preventTyping($event)" autocomplete="off" >
                            <nb-datepicker #formpicker (click)="openDatePicker('user_birth_date')"></nb-datepicker>
                            <ng-container
                                *ngIf="updateProfileForm.submitted || (user_birth_date.invalid && user_birth_date.touched)">
                                <p class="caption status-danger" *ngIf="user_birth_date.errors?.required">
                                    Birth Date is required!
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="user_gender" class="label">Gender <span class="text-danger">*</span></label>
                            <nb-select fullWidth id="user_gender" placeholder="Gender" name="user_gender" class="gender"
                                [(ngModel)]="userDetails.user_gender" #user_gender="ngModel" autofocus disabled>
                                <nb-option *ngFor="let userGender of userGenders" [value]="userGender.value">{{
                                    userGender.viewValue }}</nb-option>
                            </nb-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Email Address</label>
                            <input fullWidth type="email" [readOnly]="true" class="form-control form-control-md"
                                id="user_email" name="user_email" [(ngModel)]="userDetails.user_email"
                                #user_email="ngModel">
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Mobile Number</label>
                            <input fullWidth type="text" [readOnly]="true" class="form-control form-control-md"
                                id="user_mobile" name="user_mobile" [(ngModel)]="userDetails.user_mobile"
                                #user_mobile="ngModel">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="userRole.role == 'Partner'">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Annual Account Maintenance Setup Fees</label>
                            <input fullWidth type="text" [readOnly]="true" class="form-control form-control-md"
                                id="setup_fees" name="setup_fees" [(ngModel)]="userDetails.setup_fees"
                                #setup_fees="ngModel">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="userRole.role == 'Partner'">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Agreed Platform Charges</label>
                            <input fullWidth type="text" [readOnly]="true" class="form-control form-control-md"
                                id="agreed_platform_charges_percent" name="agreed_platform_charges_percent" [(ngModel)]="userDetails.agreed_platform_charges_percent"
                                #agreed_platform_charges_percent="ngModel">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="userRole.role == 'Partner'">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Agreed allocation to partners</label>
                            <input fullWidth type="text" [readOnly]="true" class="form-control form-control-md"
                                id="agreed_allocation_to_partners_percent" name="agreed_allocation_to_partners_percent" [(ngModel)]="userDetails.agreed_allocation_to_partners_percent"
                                #agreed_allocation_to_partners_percent="ngModel">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <button nbButton status="primary"
                                [disabled]="profileFormSubmitted || !updateProfileForm.valid"
                                [class.btn-pulse]="profileFormSubmitted">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </nb-card-body>
    </nb-card>

    <nb-card>
        <nb-card-header>Change Password</nb-card-header>
        <nb-card-body>
            <form #updatePasswordForm="ngForm" (ngSubmit)="onSubmitPasswordForm(updatePasswordForm.value)">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Current Password <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <input fullWidth [type]="showOldPassword ? 'text' : 'password'"
                                    class="form-control form-control-md" id="old_password" name="old_password"
                                    [(ngModel)]="old_password_text" #old_password="ngModel" required />
                                <div class="input-group-append" style="cursor: pointer;"
                                    (click)="togglePasswordVisibility('current')">
                                    <span class="input-group-text bg-white">
                                        <i class="fa" [ngClass]="showOldPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                                    </span>
                                </div>
                            </div>
                            <ng-container
                                *ngIf="updatePasswordForm.submitted || (old_password.invalid && old_password.touched)">
                                <p class="caption status-danger" *ngIf="old_password.errors?.required">
                                    Old Password is required!
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>New Password <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <input fullWidth [type]="showNewPassword ? 'text' : 'password'"
                                    class="form-control form-control-md" id="new_password" name="new_password"
                                    [(ngModel)]="new_password_text" #new_password="ngModel" required minlength="6" />
                                <div class="input-group-append" style="cursor: pointer;"
                                    (click)="togglePasswordVisibility('new')">
                                    <span class="input-group-text bg-white">
                                        <i class="fa" [ngClass]="showNewPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                                    </span>
                                </div>
                            </div>
                            <ng-container
                                *ngIf="updatePasswordForm.submitted || (new_password.invalid && new_password.touched)">
                                <p class="caption status-danger" *ngIf="new_password.errors?.required">
                                    New Password is required!
                                </p>
                                <p class="caption status-danger" *ngIf="new_password.errors?.minlength">
                                    New Password must be at least 6 characters!
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Confirm Password <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <input fullWidth [type]="showConfirmPassword ? 'text' : 'password'"
                                    class="form-control form-control-md" id="confirm_password" name="confirm_password"
                                    [(ngModel)]="confirm_password_text" #confirm_password="ngModel" required
                                    pattern="{{ new_password.value }}" />
                                <div class="input-group-append" style="cursor: pointer;"
                                    (click)="togglePasswordVisibility('confirm')">
                                    <span class="input-group-text bg-white">
                                        <i class="fa" [ngClass]="showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                                    </span>
                                </div>
                            </div>
                            <ng-container
                                *ngIf="updatePasswordForm.submitted || (confirm_password.invalid && confirm_password.touched)">
                                <p class="caption status-danger" *ngIf="confirm_password.errors?.required">
                                    Confirm Password is required!
                                </p>
                                <p class="caption status-danger" *ngIf="confirm_password.errors?.pattern">
                                    Confirm Password should be same as above password!
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <button nbButton status="primary" [disabled]="passwordFormSubmitted || !updatePasswordForm.valid"
                    [class.btn-pulse]="passwordFormSubmitted">
                    Submit
                </button>
            </form>
        </nb-card-body>
    </nb-card>
</div>