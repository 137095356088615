export class Users {
    status: any;
    message: any;
    result: any;
    data: any;
    users: any;
    user_details: any;
    pages: any;
    total: any;
    count: any;
    id: any;
    user_id: any;
    user_code: any;
    user_first_name: any;
    user_last_name: any;
    user_email: any;
    user_email_verified: any;
    user_mobile: any;
    user_mobile_verified: any;
    user_birth_date: any;
    user_gender: any;
    profile_image: any;
    profile_image_type: any;
    profile_image_name: any;
    profile_image_host: any;
    profile_image_url: any;
    user_other_information: any;
    user_about: any;
    user_interests: any;
    user_location_longitude: any;
    user_location_latitude: any;
    user_authentication_token: any;
    password: any;
    old_password: any;
    confirm_password: any;
    user_download_count: any;
    user_verified: any;
    user_status: any;
    user_archive: any;
    user_role_id: any;
    created_id: String;
    created_at: Date;
    updated_id: String;
    updated_at: Date;
}
