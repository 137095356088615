import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Role } from '../../../@models/role';
import { AuthService } from '../../../@services/auth.service';
import { UsersService } from '../../../@services/users.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('addSigninForm', { static: false }) public addSigninForm: NgForm;
  @ViewChild('forgotPasswordForm', { static: false }) public forgotPasswordForm: NgForm;
  @ViewChild('passwordPopup') passwordPopupTemplateRef!: TemplateRef<any>;

  user_email_text: any;
  forgot_email_text: any;
  password_text: any;
  signinFormSubmitting = false;
  forgotPasswordFormSubmitting = false;

  //for password protection
  isPopupVisibleForPlans: boolean = false;
  user_password: string = '';
  errorMessage: string = '';
  password: string = 'us0124';

  constructor(
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private toastrService: NbToastrService,
    private authService: AuthService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
     //cookie check
    //  const hasVisitedBefore = this.getCookie('hasVisitedBefore');
    //  if (!hasVisitedBefore) {
    //    this.isPopupVisibleForPlans = true;
    //  } else {
    //    this.isPopupVisibleForPlans = false;
    //  }
  }

  ngAfterViewInit(): void {
    if (this.isPopupVisibleForPlans) {
      this.openPasswordPopup();
    }
  }

  authLogin(role: Role) {
    this.authService.login(role);
  }

  onSubmitSignin = (payload) => {
    this.signinFormSubmitting = true;
    let authData = {
      'user_email': payload.user_email,
      'password': payload.password,
    };

    this.usersService.newAdminSignin(authData).subscribe(response => {
      if (response) {
        if (response.status == 1) {
          setTimeout(() => {
            this.signinFormSubmitting = false; 
          }, 3000);
          const lastName = response.result.user_last_name == null ? '' : response.result.user_last_name;
          localStorage.setItem('user_id', response.result.id);
          localStorage.setItem('user_code', response.result.user_code);
          localStorage.setItem('user_role_id', response.result.user_role_id);
          localStorage.setItem('user_name', response.result.user_first_name + ' ' + lastName);
          if (response.result.profile_image_url != null) {
            localStorage.setItem('user_image_url', response.result.profile_image_host + response.result.profile_image_url);
          }
          else {
            localStorage.setItem('user_image_url', 'assets/images/user-profile.png');
          }
          if (response.result.user_role_id == 1) {
            this.authLogin(Role.Admin);
            this.router.navigate(['pages/dashboard']).then(() => {
              this.toastrService.success('Success', response.message);
            })
          }
          else if (response.result.user_role_id == 3) {
            this.authLogin(Role.Partner);
            this.router.navigate(['partner/dashboard']).then(() => {
              this.toastrService.success('Success', response.message);
            })
          }
          this.addSigninForm.resetForm();
          this.changeRef.detectChanges();
        }
        else {
          this.signinFormSubmitting = false;
          this.toastrService.warning('Warning', response.message);
        }
      }
    }, error => {
      if (error) {
        this.signinFormSubmitting = false;
        const message = `Oops! Something went wrong.`;
        this.toastrService.danger('Failure', message);
      }
    });
  }

  onSubmitForgotPassword = (payload) => {
    this.forgotPasswordFormSubmitting = true;
    let authData = {
      'user_email': payload.forgot_email,
    };
    this.usersService.forgotPasswordAdminEmail(authData).subscribe(response => {
      if (response) {
        if (response.status == 1) {
          this.forgotPasswordFormSubmitting = false;
          this.cancel();
          this.toastrService.success('Success', response.message);
          this.router.navigate(['/login']);
        }
        else {
          this.forgotPasswordFormSubmitting = false;
          this.toastrService.danger('Failure', response.message);
        }
      }
    }, error => {
      if (error) {
        this.forgotPasswordFormSubmitting = false;
        const message = `Oops! Something went wrong.`;
      }
    });
  }

  /* Edit Success Code*/
  openforgotPasswordPopup = (templateRef: TemplateRef<any>) => {
    this.dialog.open(templateRef, {
      width: 'auto',
    });
  };

  cancel() {
    this.forgotPasswordForm.resetForm();
    this.closePopup();
  }

  // /* Close All Popup */
  closePopup() {
    this.dialog.closeAll();
  }

  openPasswordPopup(): void {
    const dialogConfig: MatDialogConfig = {
      width: 'auto',
      disableClose: true, 
    };
    this.dialog.open(this.passwordPopupTemplateRef, dialogConfig);
  }

  redirectValidation() {
    if (this.user_password == this.password) {
      this.setCookie('hasVisitedBefore', 'true', 365); 
      this.isPopupVisibleForPlans = false;
      this.errorMessage = '';
      this.dialog.closeAll();
    } else {
      this.isPopupVisibleForPlans = true;
      this.errorMessage = 'Password is incorrect!';
    }
  }

  setCookie(name: string, value: string, days: number) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  getCookie(name: string) {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return '';
  }
}
